<template>
  <div class="subjoinus">
    <div class="sub-banner bg" />
    <div class="wrapper">
      <div class="bread">
        <div class="icon"><i class="el-icon-caret-right" /></div>
        <div class="text">您的位置：首页  <i>></i>  <span>加入我们</span></div>
      </div>
      <div class="sub-menu">
        <a href="/baseHome/about" title="公司简介">公司简介</a>
        <a href="/baseHome/culture" title="企业文化">企业文化</a>
        <a href="/baseHome/honor" title="荣誉资质">荣誉资质</a>
        <a href="/baseHome/cooperation" title="合作共赢">合作共赢</a>
        <a href="/baseHome/joinus" title="加入我们" class="on">加入我们</a>
      </div>
    </div>
    <div class="sub-title" style="letter-spacing:3px;">加入我们的N个理由</div>
    <div class="wrapper">
      <div class="joinimg">
        <div class="joinimg1"><span>丰富的业余活动</span><img src="../../assets/images/join1.jpg"></div>
        <div class="joinimg2"><span style="text-align:right;">关注人才的成长</span><img src="../../assets/images/join2.jpg"></div>
        <div class="joinimg2" style="top:164px;"><span>完善的管理制度</span><img src="../../assets/images/join3.jpg"></div>
        <div class="joinimg3"><span>全面的福利关怀</span><img src="../../assets/images/join4.jpg"></div>
      </div>

      <div v-if="number < 1" style="text-align:center; font-size:14px; color:#888; height:300px;">暂无数据</div>
      <div class="card-wraper">
        <div v-for="(item, index) in list" v-show="(item.isStop === false)" :key="index" class="card" @click="jumpJoinDetail(item.jobId)">
          <div class="card-content">
            <div class="title">
              <div class="left">
                <p>{{ item.timeAdd.substring(0,10) }}</p>
                <p>{{ item.position }}</p>
              </div>
              <div class="mid">
                <p>工作地点</p>
                <p>{{ item.cityName }}</p>
              </div>
              <div class="right">
                <p>招聘人数</p>
                <p>{{ item.number }} 人</p>
              </div>
            </div>
            <div class="describe">
              <div class="describe-title">职位描述</div>
              <div class="describe-content">
                {{ item.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 详情弹窗 @refreshDataList="getDataList"-->
    <JoinUsDetail ref="JoinUsDetail" />
  </div>

</template>

<script>
import JoinUsDetail from './JoinUsDetail'
export default {
  name: '',
  components: {
    JoinUsDetail
  },
  data() {
    return {
      list: [],
      number: 0
    }
  },

  created() {
    this.getDataList()
  },
  mounted() {

  },
  methods: {
    jumpJoinDetail(id) {
      this.$nextTick(() => {
        this.$refs.JoinUsDetail.init(id)
      })
    },
    getDataList() {
      this.$http.post('/api/Job/GetJobList', { pageIndex: 0, pageSize: 100 }).then(res => {
        console.log(res)
        this.list = res.data.dataSource

        this.number = this.list.length
      })
    }
  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/sub.scss";
@import "./../../assets/scss/mixin.scss";
.subjoinus {
  .bg{background:url(./../../assets/images/aboutbg.jpg) }
  .joinimg{ position: relative; height: 327px;
    span{ display: block; position: absolute; left: 0; bottom: 0; width: 100%;  background:url(./../../assets/images/clp05.png); background-size: 100% 100%; height: 86px; line-height: 122px; color: #fff; box-sizing: border-box; padding:0 21px; font-size: 16px;}
    .joinimg1{ position:absolute; left: 0; top: 0;  overflow: hidden;width: 341px; height: 327px; }
    .joinimg1 img{width: 341px; height: 327px;transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
    .joinimg1:hover img{width: 360px; height: 345px; margin-left: -10px; margin-top:-9px;}

    .joinimg2{ position:absolute; left: 342px; top: 0;  overflow: hidden;width: 397px; height: 163px; }
    .joinimg2 img{width: 397px; height: 163px;transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
    .joinimg2:hover img{width: 421px; height: 173px; margin-left: -12px; margin-top:-5px;}

    .joinimg3{ position:absolute; right: 0; top: 0;  overflow: hidden;width: 460px; height: 327px; }
    .joinimg3 img{width: 460px; height: 327px;transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
    .joinimg3:hover img{width: 480px; height: 341px; margin-left: -10px; margin-top:-7px;}
  }

  .card-wraper {flex-wrap: wrap;display: flex;justify-content: space-between;margin: 60px 0 100px;
    .card {cursor: pointer;transition: all 0.5s; width: 585px;height: 240px;background-color: #fff;border: 1px solid #e7e7e7;border-radius: 6px;padding: 30px;box-sizing: border-box;margin:0 0 30px 0;
      &:hover {background-color: #0062db;
        p {transition: all 0.5s;color: #fff !important;}
        .describe-title,.describe-content {transition: all 0.5s;color: #fff !important;}
      }
      .card-content {transition: all 0.5s;
        .title {overflow: hidden;
          .left{ float: left; width: 58%;}
          .mid { float: left; width: 27%;}
          .right{ float: left;}
          .left p:nth-child(1), .mid p:nth-child(1), .right p:nth-child(1) {font-size: 14px;color: #999;font-family: Arial, Helvetica, sans-serif;margin-bottom: 20px;}
          .left p:nth-child(2), .mid p:nth-child(2), .right p:nth-child(2) {font-size: 16px;font-weight: bold;color: #111;}
        }
        .describe { margin-top: 30px;
          .describe-title {color: #999;font-size: 14px;margin-bottom: 15px;}
          .describe-content {@include fontGHidden(3);font-size: 14px;color: #333;line-height: 27px;}
        }
      }
    }
  }
}
</style>
